<div>
    <div class="d-flex justify-content-between">
        <h2 mat-dialog-title>{{ 'TRANSLATE.PROMOTION_DATA' | translate }}</h2>
    </div>

    <div>
        <form [formGroup]="form">
            <mat-tab-group (selectedTabChange)="tabChanged($event)">
                <mat-tab label="Datos">
                    <div class="modal-form-body">
                        <div class="row w-100 py-2">
                            <div class="col-md-12 mb-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>{{'TRANSLATE.NAME' | translate}}</mat-label>
                                    <input matInput placeholder="" formControlName="name">
                                    <mat-error *ngIf="form.controls.name.hasError('required')">
                                        {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 mb-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>{{'TRANSLATE.FROM' | translate}}</mat-label>
                                    <input matInput [matDatepicker]="dateini" formControlName="dateStart"
                                            [max]="form.value.dateEnd" placeholder="YYYY-MM-DD">
                                    <mat-hint>{{'TRANSLATE.START_DATE' | translate}}</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="dateini"></mat-datepicker-toggle>
                                    <mat-datepicker #dateini></mat-datepicker>
                                    <mat-error *ngIf="form.controls.dateStart.hasError('required')">
                                        {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 mb-2">
                                <mat-form-field style="margin-right: 10px;" class="example-full-width">
                                    <input matInput formControlName="hourStart" type="time" placeholder="Hora de Inicio" readonly
                                      atp-time-picker locale='ru' arrowStyle="{'background': 'red', 'color': 'white'}">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 mb-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>{{'TRANSLATE.TO' | translate}}</mat-label>
                                    <input matInput [matDatepicker]="datefin" formControlName="dateEnd"
                                            [min]="form.value.dateStart" placeholder="YYYY-MM-DD">
                                    <mat-hint>{{'TRANSLATE.END_DATE' | translate}}</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="datefin"></mat-datepicker-toggle>
                                    <mat-datepicker #datefin></mat-datepicker>
                                    <mat-error *ngIf="form.controls.dateEnd.hasError('required')">
                                        {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 mb-2">
                                <mat-form-field style="margin-right: 10px;" class="example-full-width">
                                    <input matInput formControlName="hourEnd" type="time" placeholder="Hora de Fin" readonly
                                      atp-time-picker locale='ru' arrowStyle="{'background': 'red', 'color': 'white'}">
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 mb-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>{{'TRANSLATE.LEVEL' | translate}}</mat-label>
                                    <mat-select formControlName="level" (selectionChange)="changueSelectLevel()">
                                        <mat-option *ngFor="let item of listLevels" [value]="item.code">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="form.controls.level.hasError('required')">
                                        {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            
                            <!--<div class="col-md-6 mb-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>{{'TRANSLATE.MAX_QUANTITY' | translate}}</mat-label>
                                    <input matInput placeholder="" formControlName="maxQuantity" type="number">
                                    <mat-error *ngIf="form.controls.maxQuantity.hasError('required')">
                                        {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.maxQuantity.hasError('min')">
                                        {{'AUTH.VALIDATION.MIN_QUANTITY_FIELD' | translate}} 1
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="col-md-6 mb-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>{{'TRANSLATE.MAX_AMOUNT' | translate}}</mat-label>
                                    <input matInput placeholder="" formControlName="maxAmount" type="number">
                                    <mat-error *ngIf="form.controls.maxAmount.hasError('required')">
                                        {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="form.controls.maxAmount.hasError('min')">
                                        {{'AUTH.VALIDATION.MIN_QUANTITY_FIELD' | translate}} 1
                                    </mat-error>
                                </mat-form-field>
                            </div>-->
        
                            <div *ngIf="id != null" class="col-md-3 pt-2 mb-4 mt-1">
                                <div class="example-full-width">
                                    <mat-slide-toggle class="toogle-margin" [checked]="this.form.value.active == '1'"
                                    (change)="$event.checked ? form.get('active').setValue('1'):form.get('active').setValue('0') ">
                                    {{ ( this.form.value.active == '1' ?  'TRANSLATE.STATUS_ACTIVE' : 'TRANSLATE.STATUS_INACTIVE') | translate }}
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Productos">
                    <div class="modal-form-body">
                        <div class="row w-100 py-2">
                            <div class="col-md-11 mb-2" *ngIf="this.form.value.level == 'C'">
                                <mat-form-field class="example-full-width">
                                    <mat-label>{{'TRANSLATE.CATEGORIES' | translate}}</mat-label>
                                    <span matSuffix *ngIf="loadMyCategories">
                                        <mat-progress-spinner [diameter]="20" mode="indeterminate"></mat-progress-spinner>
                                    </span>
                                    <mat-select [compareWith]="compareObjects" [(ngModel)]="categorySelected" [ngModelOptions]="{standalone: true}" (selectionChange)="changueSelectCategory()">
                                        <mat-option>
                                            <ngx-mat-select-search [(ngModel)]="searchMyCategories" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="changueOptionsMyCategories($event)" placeholderLabel="{{'TRANSLATE.SEARCH_SELECT' | translate}}"
                                                noEntriesFoundLabel="{{'SELECT.ZERO_RECORDS' | translate}}">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of listFilterMyCategories" [value]="item._id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-11 mb-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>{{'TRANSLATE.PRODUCTS' | translate}}</mat-label>
                                    <span matSuffix *ngIf="loadMyProducts">
                                        <mat-progress-spinner [diameter]="20" mode="indeterminate"></mat-progress-spinner>
                                    </span>
                                    <mat-select [compareWith]="compareObjects" [(ngModel)]="productsToAdd" [ngModelOptions]="{standalone: true}" multiple>
                                        <mat-option>
                                            <ngx-mat-select-search [(ngModel)]="searchMyProducts" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="changueOptionsMyProducts($event)" placeholderLabel="{{'TRANSLATE.SEARCH_SELECT' | translate}}"
                                                noEntriesFoundLabel="{{'SELECT.ZERO_RECORDS' | translate}}">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option #allProductsSelected (click)="toggleAllSelectionProducts()" [value]="0" *ngIf="listFilterMyProducts.length > 0">Todos</mat-option>
                                        <!--<mat-divider></mat-divider>-->
                                        <mat-option *ngFor="let item of listFilterMyProducts" (click)="tosslePerOneProduct(allProductsSelected.viewValue)" [value]="item" [disabled]="validateDuplicatedProducts(item)">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-1 mb-2">
                                <button mat-icon-button color="primary" (click)="addProductsToList()" matTooltip="{{'TRANSLATE.ADD' | translate}}">
                                    <mat-icon aria-label="label">add</mat-icon>
                                </button>
                            </div>

                            <div class="col-md-12 mb-2">
                                <div class="table-responsive container-table">
                                    <table class="example-full-width" mat-table [dataSource]="dataSource" matSort>
                                        <ng-container matColumnDef="product">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">{{'TRANSLATE.PRODUCT' | translate}}</th>
                                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                <b>{{element.itemSKU}}</b><br>
                                                {{element.itemName}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="type">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">{{'TRANSLATE.TYPE' | translate}}</th>
                                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                <mat-form-field style="margin-top: 10px;" class="example-full-width" appearance="outline">
                                                    <mat-label>{{'TRANSLATE.TYPE' | translate}}</mat-label>
                                                    <span matSuffix *ngIf="loadName">
                                                        <mat-progress-spinner [diameter]="20" mode="indeterminate"></mat-progress-spinner>
                                                    </span>
                                                    <mat-select [(ngModel)]="element.amountType" [ngModelOptions]="{standalone: true}" [disabled]="!element.isEdit">
                                                        <mat-option *ngFor="let item of listAmountType" [value]="item.code">
                                                            {{item.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="discount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">{{'TRANSLATE.DISCOUNT' | translate}}</th>
                                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                <mat-form-field style="margin-top: 10px;" class="example-full-width" appearance="outline">
                                                    <mat-label>{{'TRANSLATE.DISCOUNT' | translate}}</mat-label>
                                                    <input matInput [(ngModel)]="element.discount"  [ngModelOptions]="{standalone: true}" type="number" [disabled]="!element.isEdit">
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="maxQuantity">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">{{'TRANSLATE.MAX_QUANTITY' | translate}}</th>
                                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                <mat-form-field style="margin-top: 10px;" class="example-full-width" appearance="outline">
                                                    <mat-label>{{'TRANSLATE.MAX_QUANTITY' | translate}}</mat-label>
                                                    <input matInput [(ngModel)]="element.maxQuantity"  [ngModelOptions]="{standalone: true}" type="number" (ngModelChange)="verifiedValue(element)" [disabled]="!element.isEdit">
                                                </mat-form-field>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="maxAmount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;">{{'TRANSLATE.MAX_AMOUNT' | translate}}</th>
                                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                                <mat-form-field style="margin-top: 10px;" class="example-full-width" appearance="outline">
                                                    <mat-label>{{'TRANSLATE.MAX_AMOUNT' | translate}}</mat-label>
                                                    <input matInput [(ngModel)]="element.maxAmount"  [ngModelOptions]="{standalone: true}" type="number" (ngModelChange)="verifiedValue(element)" [disabled]="!element.isEdit">
                                                </mat-form-field>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="active">
                                            <th mat-header-cell *matHeaderCellDef style="text-align: center;">{{'TRANSLATE.STATUS' | translate}}</th>
                                            <td mat-cell *matCellDef="let element" style="text-align: center;">
                                              <mat-slide-toggle matTooltip="{{element.active == '1' ? 'Activo': 'Inactivo'}}"
                                                [checked]="element.active == '1'" (change)="element.active = element.active=='0' ? '1' : '0';"
                                                [disabled]="!element.isEdit">
                                              </mat-slide-toggle>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Acciones</th>
                                            <td style="text-align: center;" mat-cell *matCellDef="let element; let i =index">
                                  
                                              <button *ngIf="!element.isEdit" mat-icon-button color="accent" (click)="onEdit(element)" matTooltip="Editar">
                                                <mat-icon aria-label="label">edit</mat-icon>
                                              </button>
                                              <button *ngIf="element.isEdit" mat-icon-button color="accent" (click)="onSaveEdit(element)" matTooltip="Guardar">
                                                <mat-icon aria-label="label">save</mat-icon>
                                              </button>
                                              <button *ngIf="(!element.isEdit && !element.isNew) || element.isEdit" mat-icon-button color="warn" (click)="onDelete(i, element)" matTooltip="Eliminar">
                                                <mat-icon aria-label="label">delete</mat-icon>
                                              </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                    </table>
                                    <div class="text-table" *ngIf="dataSource.data.length == 0">
                                        <span>No hay Datos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            
            
            <div class="btn-sec d-flex justify-content-between mt-3">
                <button  type="button" mat-button (click)="onClose()">{{ 'TRANSLATE.CANCEL' | translate}}</button>
        
                <div class="button-container">
                    <div *ngIf="loadSave" class="spinner-container">
                        <mat-spinner diameter="24"></mat-spinner>
                    </div>
                    <button class="msr-3" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!form.valid || loadSave">{{ 'TRANSLATE.SAVE' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>  