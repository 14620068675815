import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { andAllWhere, andWhere, buildQuery, Normalizers, Ops, orderBy, OrderVariant, orWhere, page, select, where } from '@albatrosdeveloper/ave-utils-npm/lib/utils/query.util';
import { Observable } from 'rxjs';
import ItemAttributes, { ImageAttributes } from '@albatrosdeveloper/ave-models-npm/lib/schemas/item/item.entity';
import { ItemToSave, ItemWithTotal } from 'src/app/modules/item/item.model';
import { DahsboardInfo } from 'src/app/modules/dashboard/dashboard.model';

const URL_API = environment.domain + environment.itemAPI
const URL_S3 = environment.url_s3

@Injectable({
  providedIn: 'root'
})
export class ItemService {

    constructor(public http: HttpClient) { }

    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
    })

    getComboByBp(businessPartnerId: string): Observable<ItemWithTotal[]> {
        const query = buildQuery<ItemAttributes>(
            where('active', '1'),
            andAllWhere(<any>'businessPartner._id', Ops.eq(businessPartnerId, Normalizers.ObjectId)),
            select(['_id','code','SKU','name']),
            orderBy('SKU', OrderVariant.Asc),
        )

        const url_api = URL_API + `/item/advanced?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<ItemWithTotal[]>(url_api, { headers: this.headers});
    }

    getList(): Observable<ItemAttributes[]> {
        const query = buildQuery<ItemAttributes>(
            where('_deleted', false),
            orderBy('_id', OrderVariant.Asc),
        )

        const url_api = URL_API + `/item?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<ItemAttributes[]>(url_api, { headers: this.headers});
    }

    getListWithCount(options: { cur_page?, per_page?, search?, sort?, sd?}, filter: {businessP, warehouseIds}): Observable<ItemWithTotal[]> {
        const query = buildQuery<ItemAttributes>(
            where('SKU', Ops.like(options.search,'i')),
            orWhere('name', Ops.like(options.search,'i')),
            orWhere('description', Ops.like(options.search,'i')),
            orWhere(<any>'categories.name', Ops.like(options.search,'i')),
            andAllWhere(<any>'businessPartner._id', Ops.eq(filter.businessP, Normalizers.ObjectId)),
            andAllWhere(<any>'warehouseItem.warehouse._id', Ops.in(...filter.warehouseIds, Normalizers.ObjectId)),
            orderBy(options.sort, (options.sd == 'asc') ? (OrderVariant.Asc) : (OrderVariant.Desc)),
            page(options.cur_page, options.per_page)
        )

        const url_api = URL_API + `/item/withCount?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<ItemWithTotal[]>(url_api, { headers: this.headers});
    }

    getById(id: number): Observable<ItemAttributes> {
        const url_api = URL_API + `/item/byId/${id}`
        return this.http.get<ItemAttributes>(url_api, { headers: this.headers });
    }

    create(data: ItemToSave): Observable<ItemAttributes> {
        const url_api = URL_API + `/item`
        return this.http.post<ItemAttributes>(url_api, data, { headers: this.headers });
    }

    update(code: string, data: ItemToSave|ItemAttributes|{active}): Observable<ItemAttributes> {
        const url_api = URL_API + `/item/${code}`
        return this.http.patch<ItemAttributes>(url_api, data, { headers: this.headers });
    }

    delete(code: string): Observable<ItemAttributes> {
        const url_api = URL_API + `/item/${code}`
        return this.http.delete<ItemAttributes>(url_api, { headers: this.headers });
    }

    /* Imágenes */
    getListImageByItemId(ItemId : string){
        const url_api = URL_API + `/image?itemId=${ItemId}`
        return this.http.get<ImageAttributes[]>(url_api, { headers: this.headers});
    }

    createImage(data: FormData): Observable<ImageAttributes> {
        const url_api = URL_API + `/image/item`
        return this.http.post<ImageAttributes>(url_api, data);
    }

    updateImage(id: string, data: FormData|{active}): Observable<ImageAttributes> {
        const url_api = URL_API + `/image/item/${id}`
        return this.http.patch<ImageAttributes>(url_api, data);
    }

    deleteImage(idIm: string, idI: string): Observable<ImageAttributes> {
        const url_api = URL_API + `/image/item/${idIm}/${idI}`
        return this.http.delete<ImageAttributes>(url_api, { headers: this.headers });
    }

    saveOrderImage(data: any) {
        const url_api = URL_API + `/image/item/position`
        return this.http.post(url_api, data, { headers: this.headers });
    }
    /* Fin de imágenes */

    getImagePath(name: string) {
        let url = name ? URL_S3 + "/products/original/" + name : null
        return url
    }

    getPathImageSmall(image) {
        let url = image ? URL_S3 + '/products/150X150/' + image : null
        return url 
    }

    getProductsByCategory(filter: {businessPartner, categoryId}){
        const query = buildQuery<ItemAttributes>(
            where('_deleted', false),
            andAllWhere(<any>'businessPartner._id', Ops.eq(filter.businessPartner, Normalizers.ObjectId)),
            andAllWhere(<any>'categories._id', Ops.eq(filter.categoryId, Normalizers.ObjectId)),
            orderBy('_id', OrderVariant.Asc),
        )

        const url_api = URL_API + `/item?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<ItemAttributes[]>(url_api, { headers: this.headers});
    }

    //dashboard
    getListMany(data: any): Observable<DahsboardInfo>{
        let params = new HttpParams()

        if(data.company){
            params = params.set('companyId', data.company)
        }

        params = params.set('businessPartnerId', data.businessPartnerId)

        const url_api = URL_API + `/item/dashboard/backoffice`
        return this.http.get<DahsboardInfo>(url_api, { headers: this.headers, params: params});
    }
}