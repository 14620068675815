<!-- begin:: Footer -->
<section class="content">
	<div class="footer bg-white py-4 d-flex flex-lg-column " id="kt_footer" >
		<div class="">
			<div class="container-fluid">
				<div class=" d-flex flex-column flex-md-row align-items-center justify-content-between">
					<!--begin::Copyright-->
					<div class="text-dark order-2 order-md-1">
						<!--<span class="text-muted font-weight-bold mr-2"> &copy;</span>-->
						<!--<a href="https://www.albatrosservices.com" target="_blank" class="text-dark-75 text-hover-primary">Albatros Services</a>-->
					</div>
					<!--end::Copyright-->
				
					<!--begin::Nav-->
					<div class="text-dark order-1 order-md-2 d-flex flex-column flex-md-row align-items-center justify-content-between">
						<a href="https://plazza.com/politica" target="_blank" class="nav-link pl-3 pr-0 text-dark-75 text-hover-primary">{{ 'AUTH.GENERAL.CONDITION' | translate }}</a>
						<a href="https://plazza.com/politica/politica-privacidad" target="_blank" class="nav-link pl-3 pr-0 text-dark-75 text-hover-primary">{{ 'AUTH.GENERAL.PRIVACY' | translate }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- end:: Footer -->
