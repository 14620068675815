<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <!-- mobile menu bars -->
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="dashboard/main">
        <img src="assets/images/logoG.png" width="40" alt="" />
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
        <li class="nav-item user_profile navbar-right" ngbDropdown #myDrop="ngbDropdown">
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon [ngStyle]="{color: userAvailable == 1 ? '#388E3C' : userAvailable == 2 ? '#ad2121' : '#CCC427'}">
              {{userAvailable == 1 || userAvailable == 3 ? 'camera_front' : 'camera_rear'}}
            </mat-icon>
            <!--<div class="text-right">
              <div class="fullscreen">
                <span>{{userAvailable?.descripcion}}</span>
              </div>
            </div>-->
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 400px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li>
                  <a (click)="myDrop.close();updateAvailability(available)" *ngFor="let available of listAvailability" onClick="return false;" role="button">
                    <span class="menu-info text-center">
                      <span class="menu-title" style="float: none;">{{available.descripcion}}</span>
                    </span>
                    <span class="table-img msg-user">
                      <span [ngStyle]="{color: available.id == 1 ? '#388E3C' : available.id == 2 ? '#ad2121' : '#CCC427', 'font-size': '25px'}">&#9679;</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item user_profile" ngbDropdown #myDrop="ngbDropdown">
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <div class="info-image-welcome">
              <div class="info-welcome">
                <span><b>Bienvenid@</b></span>
                <span>{{this.user?.userLogin?.firstName}}</span>
              </div>
              <img src="assets/images/faviconG.png" class="rounded-circle" width="32" height="32" alt="Superadministrador  " />
            </div>
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>&nbsp;{{'AUTH.LOGOUT' | translate}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
