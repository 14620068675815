import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/core/service/auth.service";
import { QuestionService } from "src/app/services/addons/question/question.service";
import { ErrorManagementService } from "src/app/services/system/error-management.service";
import { SnackBarService } from "src/app/services/system/snackbar.service";

@Component({
    selector: 'app-form-answer-question',
    templateUrl: './form-answer-question.component.html',
    styleUrls: ['./form-answer-question.component.scss']
  })
  export class FormAnswerQuestionComponent implements OnInit {
   
    public form: FormGroup
    public id: any = null
    loadSave = false

    currentUser

    constructor(
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<FormAnswerQuestionComponent>,
      private errorService: ErrorManagementService,
      private questionService: QuestionService,
      private authenticationService: AuthService,
      private snackBarService: SnackBarService,
      private translate: TranslateService,
      private cdr: ChangeDetectorRef,

      @Inject(MAT_DIALOG_DATA) public data: any
    ){

      this.currentUser = this.authenticationService.currentUserValue

      this.form = this.fb.group({
        user: [''],
        email: [''],
        productCode: [''],
        productName: [''],
        question: [''],
        answer: ['', Validators.compose([Validators.required,])],
      })
    }

    ngOnInit() {
      console.log('data', this.data)
      this.initForm()
    }

    initForm() {
      if (this.data == null) {
        this.id = null
      }
      else {
        this.id = this.data._id
        this.form.setValue({
          user: this.data.questionUser.firstName + ' ' + this.data.questionUser.lastName,
          email: this.data.questionUser.email,
          productCode: this.data.itemCode,
          productName: this.data.itemCode,
          question: this.data.question,
          answer: this.data.answer ? this.data.answer : '',
        })
      }
    }

    onSubmit() {
        this.loadSave = true
        let now = new Date()
        let questionId = this.id

        let send = {
          answerUserId: this.currentUser.user.userLogin._id,
          answer: this.form.value.answer,
          answerDate: now.toISOString()
        }

        if(this.id == null){

        }else{
          this.questionService.answerQuestion(questionId,send).subscribe({
            next: (res: any) => {
              console.log(res)
              this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
              this.dialogRef.close('success')
              this.loadSave = false
              this.cdr.detectChanges()
    
            },
            error: (error) => {
              this.loadSave = false
              this.errorService.getErrorMessage(error)
              this.cdr.detectChanges()
            }
          })
        }
    }

    onClose(){
      this.dialogRef.close();
    }
  }