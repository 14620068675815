<section class="content">
    <div class="container-fluid">
        <br>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div *ngIf="loadData">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <div class="header d-flex justify-content-between">
                        <div class="p-2"></div>
                        <div style="display: flex;">
                            <button mat-icon-button color="primary" matTooltip="{{'TRANSLATE.CAPTION' | translate}}" [matMenuTriggerFor]="infoMenu">
                                <mat-icon>info_outline</mat-icon>
                            </button>
                            <mat-menu #infoMenu="matMenu">
                                <div style="max-width : 300px; padding: 0px 5px;">
                                    <div class="legend_info" fxLayoutAlign="start center" style="margin-top: 0.4em;">
                                    <mat-icon style="float: left; font-size: 18px;" color="warn" matTooltip="{{'TRANSLATE.THERE_ARE_ACTIVE_REPORTS' | translate}}">error</mat-icon>
                                    {{'TRANSLATE.THERE_ARE_ACTIVE_REPORTS' | translate}}
                                    </div>
                                    <div class="legend_info" fxLayoutAlign="start center" style="margin-top: 0.4em;">
                                    <mat-icon style="float: left; font-size: 18px; color: rgb(255, 217, 0);" matTooltip="{{'TRANSLATE.QUESTION_WITHOUT_ANSWER' | translate}}">error</mat-icon>
                                    {{'TRANSLATE.QUESTION_WITHOUT_ANSWER' | translate}}
                                    </div>
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="body table-responsive">
                        <table mat-table [dataSource]="dataSource" class="w-100" matSort (matSortChange)="changeSort($event)" matSortDisableClear multiTemplateDataRows>

                            <ng-container matColumnDef="det">
                                <th mat-header-cell *matHeaderCellDef class="pr-0 pl-0"></th>
                                <td mat-cell *matCellDef="let element; let i =index" class="pr-0 pl-0" style="width: 1px">
                                    {{i}}
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="text-content" style="display: flex; align-items: center;">
                                        <mat-icon *ngIf="isActiveQAs(element)" 
                                            color="warn" matTooltip="{{'TRANSLATE.THERE_ARE_ACTIVE_REPORTS' | translate}}">error</mat-icon>
                                        <mat-icon *ngIf="!isActiveQAs(element) && (element.answer === undefined || element.answer == null)" 
                                            style="color: rgb(255, 217, 0)" matTooltip="{{'TRANSLATE.QUESTION_WITHOUT_ANSWER' | translate}}">error</mat-icon>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="item">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.ITEM' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <b>{{element.itemCode}}</b><br>
                                    {{element.itemName}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="user">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.USER' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <b>{{element.questionUser.firstName}} {{element.questionUser.lastName}}</b><br>
                                    {{element.questionUser.email}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="question">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.QUESTION' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <span class="text">{{element.question}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="answer">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.ANSWER' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <span class="text">{{element.answer}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.DATE_OF'| translate}} {{'TRANSLATE.CREATION'| translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{getDate(element.questionDate)}} {{getHour(element.questionDate)}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="active">
                                <th mat-header-cell *matHeaderCellDef>{{'TRANSLATE.STATUS' | translate}}</th>
                                <td mat-cell *matCellDef="let element">
                                  <mat-slide-toggle matTooltip="{{element.active == '1' ? 'Activo': 'Inactivo'}}"
                                    [checked]="element.active == '1'" (change)="onchangeActive($event , element)">
                                  </mat-slide-toggle>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                        <button mat-icon-button color="primary" [matMenuTriggerFor]="menu"
                                            matTooltip="{{'TRANSLATE.ACTIONS' | translate}}">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                    <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="openForm(element)">
                                                <mat-icon color="primary">edit</mat-icon>
                                                <span>{{'TRANSLATE.ANSWER' | translate}}</span>
                                            </button>
                                            <button mat-menu-item (click)="openReports(element, 'question')" *ngIf="element.questionReports !== undefined && element.questionReports != null && element.questionReports?.length > 0">
                                                <mat-icon color="primary">error</mat-icon>
                                                <span>{{'TRANSLATE.QUESTION_REPORTS' | translate}}</span>
                                            </button>
                                            <button mat-menu-item (click)="openReports(element, 'answer')" *ngIf="element.answerReports !== undefined && element.answerReports != null && element.answerReports?.length > 0">
                                                <mat-icon color="primary">error</mat-icon>
                                                <span>{{'TRANSLATE.ANSWER_REPORTS' | translate}}</span>
                                            </button>
                                            <button mat-menu-item (click)="onDelete(element)">
                                                <mat-icon color="warn">delete</mat-icon>
                                                <span>{{'TRANSLATE.DELETE' | translate}}</span>
                                            </button>
                                    </mat-menu>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <div *ngIf="this.dataSource.data.length === 0" class="mat-table-message">Por favor, agregue los puntos de entrega.
                        </div>
                        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[100, 200, 300, 400]" [length]="totalResults"
                        [showFirstLastButtons]="true" (page)="changePage($event)"></mat-paginator>
                            
                        <ngx-loading [show]="loadData"></ngx-loading>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>