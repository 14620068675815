import WarehouseAttributes from '@albatrosdeveloper/ave-models-npm/lib/schemas/warehouse/warehouse.entity';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { WarehouseWithTotal } from 'src/app/modules/warehouse/warehouse.model';
import { ViewImageComponent } from 'src/app/shared/view-image/view-image.component';

import { Courier } from 'src/app/modules/warehouse/courier.model';
import { SnackBarService } from 'src/app/services/system/snackbar.service';
import { ErrorManagementService } from 'src/app/services/system/error-management.service';
import { CustomDialogComponent } from 'src/app/shared/custom-dialog/custom-dialog.component';
import ItemAttributes from '@albatrosdeveloper/ave-models-npm/lib/schemas/item/item.entity';
import { QuestionService } from 'src/app/services/addons/question/question.service';
import { QuestionFilter, QuestionWithTotal } from 'src/app/modules/addons/questions/question.model';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import ItemQuestionAttributes, { ReportAttributes } from '@albatrosdeveloper/ave-models-npm/lib/schemas/item-question/item-question.entity';
import { ReportService } from 'src/app/services/addons/report/report.service';

@Component({
  selector: 'app-question-answer-report',
  templateUrl: './question-answer-report.component.html',
  styleUrls: ['./question-answer-report.component.scss']
})
export class QuestionAnswerReportComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = [
    'det',
    'user',
    'comment',
    'createdAt',
    'active',
    'action'
  ];
  
  dataSource = new MatTableDataSource<ReportAttributes>([])
  loadData = false

  totalResults = 0
  search: string = ''
  pageSize = 100
  pageIndex = 1
  sortActive = 'code'
  sortDirection = 'asc'

  public form: FormGroup
  public id: any = null
  loadSave = false

  currentUser

  constructor(
    //private warehouseService: WarehouseService,
    private fb: FormBuilder,
    private questionService: QuestionService,
    private reportService: ReportService,
    private snackBarService: SnackBarService,
    private errorService: ErrorManagementService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthService,
    private dialogRef: MatDialogRef<QuestionAnswerReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.currentUser = this.authenticationService.currentUserValue

    this.form = this.fb.group({
      user: [''],
      email: [''],
      productCode: [''],
      productName: [''],
      question: [''],
      answer: ['', Validators.compose([Validators.required,])],
    })
   }

  ngOnInit(): void {

    if(this.data == null){

    }else {
      this.id = this.data.questionId
    }

    this.getList()
  }

  applyFilter(filterValue: string) {
    this.pageIndex = 1
    this.search = filterValue
    //this.getList()
  }

  changeSort(e) {
    this.sortActive = e.active
    this.sortDirection = e.direction
    //this.getList()
  }

  changePage(e) {
    this.pageIndex = (e.pageIndex + 1)
    this.pageSize = e.pageSize
    //this.getList()
  }

  getList(){
    this.dataSource.data = this.data.data
  }
  
  onchangeActive(e, element: ReportAttributes) {

    let send = {
      itemQuestionId: this.data.questionId,
      type: this.data.type == 'question' ? 'Q' : 'A',
      active: e.checked ? '1' : '0'
    }
    this.reportService.update(String(element._id), send).subscribe({
      next: (res: ReportAttributes) => {
        this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
        this.cdr.detectChanges()
      },
      error: (error) => {
        e.source.checked = element.active
        this.errorService.getErrorMessage(error)
        this.cdr.detectChanges()
      }
    })
  }

  onDelete(index, element) {
    let obsLoad = new BehaviorSubject(null)
    let d = {
      title: 'Eliminar',
      body: "¿Está seguro de eliminar este registro?",
      cancel: 'No',
      confirm: 'Si',
      wait: "Espere por favor...",
      obs: obsLoad
    }
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: "400px",
      data: d,
      disableClose: true,
    });

    let subs = obsLoad.subscribe(res => {
      if (res == true) {
        //type:string, reportId:string, questionId:string
        this.reportService.delete((this.data.type == 'question' ? 'Q' : 'A'), String(element._id), this.data.questionId).subscribe({
          next: (res: ReportAttributes) => {
            obsLoad.next(false)
            this.data.data.splice(index,1)
            this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
            this.getList()
          },
          error: (error) => {
            obsLoad.next(false)
            this.errorService.getErrorMessage(error)
          }
        })
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      subs.unsubscribe()
    });
  }

  getDate(date: Date) {
    return moment(date).format("YYYY-MM-DD")
  }

  getHour(date: Date) {
    return moment(date).format("HH:mm:ss")
  }

  onSubmit(){
    this.dialogRef.close('success')
  }

  /*onClose(){
    this.dialogRef.close();
  }*/
}