import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragScrollModule } from 'ngx-drag-scroll';
import { AgmCoreModule } from '@agm/core';
import { environment } from './../../environments/environment';

import { MaterialModule } from './material.module';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { ViewImageComponent } from './view-image/view-image.component';
import { TranslateModule } from '@ngx-translate/core';
import { SortManuallyComponent } from './sort-manually/sort-manually.component';
import { LocationMapComponent } from './location-map/location-map.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    CustomDialogComponent,
    ViewImageComponent,
    SortManuallyComponent,
    LocationMapComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MatButtonModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    DragScrollModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    AgmCoreModule.forRoot({
			apiKey: environment.googleApiKey,
			libraries: ['places']
		}),
  ],
  entryComponents: [
    CustomDialogComponent,
    SortManuallyComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    CustomDialogComponent,
    SortManuallyComponent,
  ]
})
export class SharedModule {}
