<div>
    <div class="d-flex justify-content-between">
        <h2 mat-dialog-title>{{ 'TRANSLATE.REPORTS' | translate }}</h2>
    </div>

    <div>
        <form [formGroup]="form">

            <div>
                <span><b>{{data.type == 'question' ? 'Pregunta' : 'Respuesta'}}</b></span><br>
                <span>{{data.qa}}</span>
            </div>

            <div class="body table-responsive">
                <table mat-table [dataSource]="dataSource" class="w-100" matSort (matSortChange)="changeSort($event)" matSortDisableClear multiTemplateDataRows>
    
                    <ng-container matColumnDef="det">
                        <th mat-header-cell *matHeaderCellDef class="pr-0 pl-0"></th>
                        <td mat-cell *matCellDef="let element; let i =index" class="pr-0 pl-0" style="width: 1px">
                            {{i}}
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.USER' | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            <b>{{element.reportUser.firstName}} {{element.reportUser.lastName}}</b><br>
                            {{element.reportUser.email}}
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="comment">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.COMMENT' | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.comment}}
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'TRANSLATE.DATE_OF'| translate}} {{'TRANSLATE.CREATION'| translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            {{getDate(element.reportDate)}} {{getHour(element.reportDate)}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef>{{'TRANSLATE.STATUS' | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                          <mat-slide-toggle matTooltip="{{element.active == '1' ? 'Activo': 'Inactivo'}}"
                            [checked]="element.active == '1'" (change)="onchangeActive($event , element)">
                          </mat-slide-toggle>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element; let i=index">
                                <button mat-icon-button color="primary" [matMenuTriggerFor]="menu"
                                    matTooltip="{{'TRANSLATE.ACTIONS' | translate}}">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="onDelete(i,element)">
                                        <mat-icon color="warn">delete</mat-icon>
                                        <span>{{'TRANSLATE.DELETE' | translate}}</span>
                                    </button>
                            </mat-menu>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div *ngIf="this.dataSource.data.length === 0" class="mat-table-message">No hay registros.
                </div>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[100, 200, 300, 400]" [length]="totalResults"
                [showFirstLastButtons]="true" (page)="changePage($event)"></mat-paginator>
                    
                <ngx-loading [show]="loadData"></ngx-loading>
            </div>

            <div class="btn-sec d-flex justify-content-between mt-3">
                <!--<button  type="button" mat-button (click)="onClose()">{{ 'TRANSLATE.CANCEL' | translate}}</button>-->
                <div>

                </div>
                <div class="button-container">
                    <div *ngIf="loadSave" class="spinner-container">
                        <mat-spinner diameter="24"></mat-spinner>
                    </div>
                    <button class="msr-3" mat-raised-button color="primary" (click)="onSubmit()">{{ 'TRANSLATE.OK' | translate}}</button>
                </div>
            </div>
        </form>
        
    </div>
</div>  