<div>
    <div class="d-flex justify-content-between">
        <h2 mat-dialog-title>{{ 'TRANSLATE.QUESTION' | translate }}</h2>
    </div>

    <div>
        <form [formGroup]="form">
            <div class="modal-form-body">
                <div class="row w-100">
                    <div class="col-md-6 mb-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{'TRANSLATE.USER' | translate}}</mat-label>
                            <input matInput placeholder="" formControlName="user" readonly>
                            <mat-error *ngIf="form.controls.user.hasError('required')">
                                {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 mb-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{'TRANSLATE.EMAIL' | translate}}</mat-label>
                            <input matInput placeholder="" formControlName="email" readonly>
                            <mat-error *ngIf="form.controls.email.hasError('required')">
                                {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 mb-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{'TRANSLATE.PRODUCT_CODE' | translate}}</mat-label>
                            <input matInput placeholder="" formControlName="productCode">
                            <mat-error *ngIf="form.controls.productCode.hasError('required')">
                                {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 mb-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{'TRANSLATE.PRODUCT_NAME' | translate}}</mat-label>
                            <input matInput placeholder="" formControlName="productName">
                            <mat-error *ngIf="form.controls.productName.hasError('required')">
                                {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div class="col-md-12 mb-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{'TRANSLATE.QUESTION' | translate}}</mat-label>
                            <input matInput placeholder="" formControlName="question" readonly>
                            <mat-error *ngIf="form.controls.question.hasError('required')">
                                {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 mb-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{'TRANSLATE.ANSWER' | translate}}</mat-label>
                            <textarea matInput placeholder="" formControlName="answer"></textarea>
                            <mat-error *ngIf="form.controls.answer.hasError('required')">
                                {{'TRANSLATE.REQUIRED_FIELD' | translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            
            <div class="btn-sec d-flex justify-content-between mt-3">
                <button  type="button" mat-button (click)="onClose()">{{ 'TRANSLATE.CANCEL' | translate}}</button>
        
                <div class="button-container">
                    <div *ngIf="loadSave" class="spinner-container">
                        <mat-spinner diameter="24"></mat-spinner>
                    </div>
                    <button class="msr-3" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!form.valid || loadSave">{{ 'TRANSLATE.SAVE' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>  