import { OnDestroy } from '@angular/core';
// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent implements OnInit, OnDestroy {

  loadView = false
  obs
  constructor(
    public dialogRef: MatDialogRef<CustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.obs) {
      this.obs = this.data.obs.subscribe(res => {
        if (res == true) {
          this.loadView = true
        }
        if (res == false) {
          this.loadView = false
          this.dialogRef.close()
        }
      })
    }

  }

  onNoClick(): void {
    this.dialogRef.close('cancel');
  }

  /**
   * Close dialog with true result
   */
  onYesClick(): void {
    if (this.obs) {
      this.data.obs.next(true)
    } else {
      this.dialogRef.close('yes');
    }
    /* Server loading imitation. Remove this */
    //this.dialogRef.close('yes'); // Keep only this row
  }
  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe()
    }
  }
}
