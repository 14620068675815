import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import BusinessPartnerAttributes from "@albatrosdeveloper/ave-models-npm/lib/schemas/businessPartner/businessPartner.entity"
import { BusinessPartnersWithTotal, BusinessPartnerFilter } from '../../../modules/setting/company/business-partner.model';
import { andAllWhere, buildQuery, Normalizers, Ops, orderBy, OrderVariant, orWhere, page, where } from '@albatrosdeveloper/ave-utils-npm/lib/utils/query.util';
import { Observable } from 'rxjs';

const URL_API = environment.domain + environment.clientAPI
const URL_S3 = environment.url_s3

@Injectable({
  providedIn: 'root'
})
export class BusinessPartnerService {

    constructor(public http: HttpClient) { }

    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    })

    getList(): Observable<BusinessPartnerAttributes[]> {
        const query = buildQuery<BusinessPartnerAttributes>(
            where('_deleted', false),
            orderBy('code', OrderVariant.Asc),
        )

        const url_api = URL_API + `/business-partner?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<BusinessPartnerAttributes[]>(url_api, { headers: this.headers});
    }

    getCombo(): Observable<BusinessPartnerAttributes[]> {
        const query = buildQuery<BusinessPartnerAttributes>(
            where('active', '1'),
            orderBy('code', OrderVariant.Asc),
        )

        const url_api = URL_API + `/business-partner?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<BusinessPartnerAttributes[]>(url_api, { headers: this.headers});
    }

    getComboByCompany(companyId: string): Observable<BusinessPartnerAttributes[]> {
        const query = buildQuery<BusinessPartnerAttributes>(
            where('active', '1'),
            andAllWhere(<any>'companies._id', Ops.eq(companyId, Normalizers.ObjectId)),
            orderBy('code', OrderVariant.Asc),
        )

        const url_api = URL_API + `/business-partner?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<BusinessPartnerAttributes[]>(url_api, { headers: this.headers});
    }

    getListWithCount(filter: BusinessPartnerFilter, options: { cur_page?, per_page?, search?, sort?, sd?}): Observable<BusinessPartnersWithTotal[]> {
        const query = buildQuery<BusinessPartnerAttributes>(
            where('code', Ops.like(options.search,'i')),
            orWhere('businessName', Ops.like(options.search,'i')),
            orWhere('description', Ops.like(options.search,'i')),
            orWhere('emailOfficial', Ops.like(options.search,'i')),
            orWhere('phoneNumberBusiness', Ops.like(options.search,'i')),
            orWhere('mobileNumberBusiness', Ops.like(options.search,'i')),
            orWhere('RTN', Ops.like(options.search,'i')),
            orWhere('idLegalRepresentative', Ops.like(options.search,'i')),
            orWhere(<any>'productiveSector.name', Ops.like(options.search,'i')),
            orWhere(<any>'address.country.name', Ops.like(options.search,'i')),
            orWhere(<any>'address.locationOne.name', Ops.like(options.search,'i')),
            orWhere(<any>'address.locationTwo.name', Ops.like(options.search,'i')),
            orWhere(<any>'address.locationThree.name', Ops.like(options.search,'i')),
            andAllWhere(<any>'company._id', Ops.in(...filter.companies, Normalizers.ObjectId)),
            orderBy(options.sort, (options.sd == 'asc') ? (OrderVariant.Asc) : (OrderVariant.Desc)),
            page(options.cur_page, options.per_page)
        )

        const url_api = URL_API + `/business-partner/withCount?filter=${encodeURIComponent(JSON.stringify(query))}`
        return this.http.get<BusinessPartnersWithTotal[]>(url_api, { headers: this.headers});
    }

    getById(id: string): Observable<BusinessPartnerAttributes> {
        const url_api = URL_API + `/business-partner/byId/${id}`
        return this.http.get<BusinessPartnerAttributes>(url_api, { headers: this.headers });
    }

    create(data: FormData): Observable<BusinessPartnerAttributes> {
        const url_api = URL_API + `/business-partner`
        return this.http.post<BusinessPartnerAttributes>(url_api, data);
    }

    update(id: string, data: FormData): Observable<BusinessPartnerAttributes> {
        const url_api = URL_API + `/business-partner/${id}`
        return this.http.patch<BusinessPartnerAttributes>(url_api, data);
    }

    updateActive(id: string, data: any): Observable<BusinessPartnerAttributes> {
        const url_api = URL_API + `/business-partner/active/${id}`
        return this.http.patch<BusinessPartnerAttributes>(url_api, data);
    }

    delete(id: string): Observable<BusinessPartnerAttributes> {
        const url_api = URL_API + `/business-partner/${id}`
        return this.http.delete<BusinessPartnerAttributes>(url_api, { headers: this.headers });
    }

    getImagePath(name: string) {
        let url = name ? URL_S3 + "/bp/" + name : null
        return url
    }

    updateBanner(id: string, data: any): Observable<any> {
        const url_api = URL_API + `/business-partner/add-tp/${id}`
        return this.http.patch<any>(url_api, data);
    }

}