import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeocodeService } from '../../services/geocode.service';
declare const google: any;

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationMapComponent implements OnInit {

  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;

  // por defecto para iniciar google
  activeReplace = false
  addressOriginal = ''
  dinamicAddress = ''
  address = '';

  location: any = {
    lat: 14.1007469,
    lng: -87.191648
  }
  centerLocation = {
    lat: 14.1007469,
    lng: -87.191648
  }

  loading: boolean; // loadingSerach Location
  loadSave = false

  map: any;

  constructor(
    private geocodeService: GeocodeService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public dialogRef: MatDialogRef<LocationMapComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.mapsAPILoader.load().then((event) => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: any = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.location.lat = place.geometry.location.lat();
          this.location.lng = place.geometry.location.lng();
          this.address = place.formatted_address
        });
      });
    });
  }

  showLocation() {
    this.addressToCoordinates();
  }


  addressToCoordinates() {
    this.loading = true;
    this.geocodeService.geocodeAddress(this.address)
      .subscribe((location: any) => {
        console.log(location)
        this.location = location;
        this.loading = false;
      }
    );
  }

  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('CurrentPos'));
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('SearchCustom'));

    this.initForm()

    this.map.addListener("dragend", () => {
      this.location.lat = this.centerLocation.lat
      this.location.lng = this.centerLocation.lng
      this.onGetAddressByLocation(this.location.lat, this.location.lng)
    });
  }

  initForm() {
    if (this.data.latitude != null && this.data.latitude != '') { //&& Number(this.data.latitude) != 0
      this.location.lat = Number(this.data.latitude)
      this.location.lng = Number(this.data.longitude)
      this.onGetAddressByLocation(this.location.lat, this.location.lng)
      this.addressOriginal = (this.data.address == null ? '' : this.data.address)
      this.dinamicAddress = (this.data.address == null ? '' : this.data.address)

    } else {
      let dep = (this.data.dep == null ? '' : this.data.dep).toLowerCase()
      let address = (this.data.address == null ? '' : this.data.address)

      let res = (address == '' ? '' : (address + ', ')) + (dep == '' ? '' : (dep + ', ')) + (this.data.country.toLowerCase())
      this.address = res
      this.addressOriginal = address
      this.dinamicAddress = address

      this.addressToCoordinates()
    }
  }
  
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location.lat = position.coords.latitude;
        this.location.lng = position.coords.longitude;
        //this.zoom = 8;
        this.onGetAddressByLocation(this.location.lat, this.location.lng);
      });
    } else {
      this.onGetAddressByLocation(this.location.lat, this.location.lng)
    }
  }

  centerChange(e) {
    this.centerLocation.lat = e.lat
    this.centerLocation.lng = e.lng
  }

  onGetAddressByLocation(la, ln) {
    this.loading = true;
    this.geocodeService.getAddress(la, ln)
      .subscribe((res: any) => {
        if (res.results.length >= 1) {
          //this.zoom = 12;
          this.address = res.results[0].formatted_address;
          this.changeAddres()
        } else {
          console.log("No se encontraron Resultados")
        }
        this.loading = false;
      }
    );
  }

  changeCheck(e) {
    this.activeReplace = e.checked
    this.changeAddres()
  }

  changeAddres() {
    if (this.activeReplace) {
      this.dinamicAddress = this.address
    } else {
      this.dinamicAddress = this.addressOriginal
    }
  }
  
  onSave(){
    this.dialogRef.close({
      //address: this.address,
      address: this.dinamicAddress,
      latitude: this.location.lat,
      longitude: this.location.lng
    })
  }

  onClose() {
    this.dialogRef.close('Close')
  }

}