import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sort-manually',
  templateUrl: './sort-manually.component.html',
  styleUrls: ['./sort-manually.component.scss']
})
export class SortManuallyComponent implements OnInit {

  position: number = 0
  leng: number = 0

  constructor(
    public dialogRef: MatDialogRef<SortManuallyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.position = this.data.posi
    this.leng = this.data.lengt
  }

  ngOnInit() {
  }

  onCancel(): void {
    this.dialogRef.close('Cancel')
  }

  onSubmit() {
    if (this.position < 1) {
      return
    }
    if (this.position > this.leng) {
      return
    }
    this.dialogRef.close(this.position)
  }
}
