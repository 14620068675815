import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../../config/config.service';
import { AuthService } from '../../core/service/auth.service';
import { RightSidebarService } from '../../core/service/rightsidebar.service';
import { WINDOW } from '../../core/service/window.service';
import { LanguageService } from '../../core/service/language.service';
import { UnsubscribeOnDestroyAdapter } from '../../shared/UnsubscribeOnDestroyAdapter';
import { getValidateSomeCompany } from 'src/app/utils/company-validator.strategy';
import BusinessPartnerAttributes from '@albatrosdeveloper/ave-models-npm/lib/schemas/businessPartner/businessPartner.entity';
import { SnackBarService } from 'src/app/services/system/snackbar.service';
import { BusinessPartnerService } from 'src/app/services/setting/company/businessPartner.service';
import { ErrorManagementService } from 'src/app/services/system/error-management.service';
import { TranslateService } from '@ngx-translate/core';
import { BusinessPartnerToSave } from 'src/app/modules/setting/company/business-partner.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LegalRepresentativeValidator } from 'src/app/pages/apps/setting/company/legal-representative.validator';
import { matchingPhone } from 'src/app/utils/app-validor';
import { TypePersonBPEnum } from 'src/app/utils/company/company';

const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit
{
  public config: any = {};
  isNavbarCollapsed = true;
  isNavbarShow: boolean;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;

  windowWidth = 0

  user = null

  listAvailability: any[] = [
    {id:1, descripcion: 'Disponible'},
    {id:2, descripcion: 'No Disponible'},
    {id:3, descripcion: 'Saturado'},
  ];

  userAvailable = null

  currentUser

  loadData = false
  data //user

  public form: FormGroup
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    private cdr: ChangeDetectorRef,
    private businessPartner: BusinessPartnerService,
    private snackBarService: SnackBarService,
    private errorService: ErrorManagementService,
    private translate: TranslateService,
    private fb: FormBuilder, 

  ) {
    super();

    this.currentUser = this.authService.currentUserValue
    this.user = this.authService.currentUserValue ? this.authService.currentUserValue.user : null

    this.form = this.fb.group({
      company: [null, Validators.compose([Validators.required,])],
      personType: [null, Validators.compose([Validators.required,])],
      idLegalRepresentative: [''],
      documentType: [null, Validators.compose([Validators.required,])],
      RTN: [null, Validators.compose([Validators.required,])],
      businessName: ['', Validators.compose([Validators.required,])],
      productiveSector: [null],
      description: [''],
      emailOfficial: ['', Validators.compose([Validators.required, Validators.email,])],
      //countryPhone: ['', Validators.compose([Validators.required,])],
      //phoneNumber: ['', Validators.compose([Validators.required,])],
      logo: [null],
      active: '1',

      /* Dirección */
      addressType: [null],
      country: [null, Validators.compose([Validators.required,])],
      locationOne: [null, Validators.compose([Validators.required,])],
      locationTwo: [null, Validators.compose([Validators.required,])],
      locationThree: [null],
      address: ['', Validators.compose([Validators.required,])],
      reference: [''],
      latitude: ['0', Validators.compose([Validators.required,])],
      longitude: ['0', Validators.compose([Validators.required,])],

      /* Cuenta bancaria para liquidación */
      accountType: [null, Validators.compose([Validators.required,])],
      bank: [null, Validators.compose([Validators.required,])],
      titularFirstName1: ['', Validators.compose([Validators.required,Validators.pattern("^[a-zA-ZÀ-ÿ\u00f1\u00d1]+$")])],
      titularLastName1: ['', Validators.compose([Validators.required,Validators.pattern("^[a-zA-ZÀ-ÿ\u00f1\u00d1]+$")])],
      documentTypeId: ['', Validators.compose([Validators.required,])],
      titularIdentificationNumber: ['', Validators.compose([Validators.required,])],
      accountNumber: ['', Validators.compose([Validators.required,])],
      additionalInformation: [''],
    },{
      //validator: [LegalRepresentativeValidator.RequireId, matchingPhone]
    })

  }
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' }
  ];
  notifications: any[] = [
    {
      userImg: 'assets/images/user/user1.jpg',
      userName: 'Sarah Smith',
      time: '14 mins ago',
      message: 'Please check your mail'
    },
    {
      userImg: 'assets/images/user/user2.jpg',
      userName: 'Airi Satou',
      time: '22 mins ago',
      message: 'Work Completed !!!'
    },
    {
      userImg: 'assets/images/user/user3.jpg',
      userName: 'John Doe',
      time: '3 hours ago',
      message: 'kindly help me for code.'
    },
    {
      userImg: 'assets/images/user/user4.jpg',
      userName: 'Ashton Cox',
      time: '5 hours ago',
      message: 'Lets break for lunch...'
    },
    {
      userImg: 'assets/images/user/user5.jpg',
      userName: 'Sarah Smith',
      time: '14 mins ago',
      message: 'Please check your mail'
    },
    {
      userImg: 'assets/images/user/user6.jpg',
      userName: 'Airi Satou',
      time: '22 mins ago',
      message: 'Work Completed !!!'
    },
    {
      userImg: 'assets/images/user/user7.jpg',
      userName: 'John Doe',
      time: '3 hours ago',
      message: 'kindly help me for code.'
    }
  ];

  @HostListener('window:resize')
  public onWindowResize(): void {
    this.windowWidth = window.innerWidth
    this.onWindowScroll()
    this.cdr.detectChanges()
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;

    if(this.windowWidth > 767) {
      if (offset > 50) {
        this.isNavbarShow = true;
      } else {
        this.isNavbarShow = false;
      }
    } else {
      this.isNavbarShow = true;
    } 
  }
  ngOnInit() {
    this.onWindowResize()
    this.config = this.configService.configData;

    this.langStoreValue = localStorage.getItem('lang');
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    this.onGetBusinessPartner()
  }

  onGetBusinessPartner() {
    this.loadData = true
    
    let businessPartner = getValidateSomeCompany(this.currentUser.user.businessPartners)._id

    this.businessPartner.getById(String(businessPartner)).subscribe({
      next: (res: BusinessPartnerAttributes) => {
        if(res)
          this.data = res

          this.userAvailable = res.status

          this.form.setValue({
            company: this.data.company ? this.data.company.code : null,
            personType: this.data.typePerson || null,
            idLegalRepresentative: this.data.idLegalRepresentative || '',
            documentType: this.data.typeDocument ? this.data.typeDocument._id : null,
            RTN: this.data.RTN || null,
            businessName: this.data.businessName || null,
            productiveSector: this.data.productiveSector ? this.data.productiveSector._id : null,
            description: this.data.description || null,
            emailOfficial: this.data.emailOfficial || null,
            logo: this.data.logo ? this.data.logo : null,
            active: this.data.active,

            /* Dirección */
            addressType: null,
            country: this.data.address && this.data.address.country ? this.data.address.country._id : null,
            locationOne: this.data.address && this.data.address.locationOne ? this.data.address.locationOne._id : null,
            locationTwo: this.data.address && this.data.address.locationTwo ? this.data.address.locationTwo._id : null,
            locationThree: this.data.address && this.data.address.locationThree ? this.data.address.locationThree._id : null,
            address: this.data.address ? this.data.address.address : null,
            reference: this.data.address ? this.data.address.reference : null,
            latitude: this.data.address ? this.data.address.latitude : '0',
            longitude: this.data.address ? this.data.address.longitud : '0',

            /* Cuenta bancaria para liquidación */
            accountType: this.data.accountPaymentLiquidation ? this.data.accountPaymentLiquidation.typeAccount._id : null,
            bank: this.data.accountPaymentLiquidation ? this.data.accountPaymentLiquidation.bank._id : null,
            titularFirstName1: this.data.accountPaymentLiquidation ? (this.data.accountPaymentLiquidation.titularFirstName1 || null) : null,
            titularLastName1: this.data.accountPaymentLiquidation ? (this.data.accountPaymentLiquidation.titularLastName1 || null): null,
            documentTypeId: this.data.accountPaymentLiquidation ? (this.data.accountPaymentLiquidation.documentType ? this.data.accountPaymentLiquidation.documentType : null || null) : null,
            titularIdentificationNumber: this.data.accountPaymentLiquidation ? (this.data.accountPaymentLiquidation.titularIdentificationNumber || null) : null,
            accountNumber: this.data.accountPaymentLiquidation ? this.data.accountPaymentLiquidation.numberAccount : null,
            additionalInformation: this.data.accountPaymentLiquidation ? (this.data.accountPaymentLiquidation.additionalInformation || '') : '',
          })

          console.log(this.data)
        this.loadData = false
      },
      error: (error) => {
        this.loadData = false
        this.errorService.getErrorMessage(error)
        this.cdr.detectChanges()
      }
    })
  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
  public toggleRightSidebar(): void {
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSidebar = isRunning;
      }
    );

    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }
  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }

  getAvailable(){
    
  }

  updateAvailability(element){

    this.userAvailable = element.id

    let businessPartnerForm = {
      companyCode: this.form.value.company,
      typePersonId: this.form.value.personType._id,
      idLegalRepresentative: this.form.value.personType.code == TypePersonBPEnum.JURIDICO ? this.form.value.idLegalRepresentative : '', // Sólo jurídico
      typeDocumentId: this.form.value.documentType,
      RTN: this.form.value.RTN,
      businessName: this.form.value.businessName,
      productiveSectorId: this.form.value.productiveSector,
      description: this.form.value.description,
      emailOfficial: this.form.value.emailOfficial,
      //phoneNumberBusiness: this.data.phoneNumberBusiness,
      address: {
        addressTypeId: this.form.value.addressType,
        countryId: this.form.value.country,
        locationOneId: this.form.value.locationOne,
        locationTwoId: this.form.value.locationTwo,
        locationThreeId: this.form.value.locationThree,
        address: this.form.value.address,
        reference: this.form.value.reference,
        latitude: this.form.value.latitude,
        longitud: this.form.value.longitude,
        isPrincipal: this.data.address ? this.data.address.isPrincipal : true,
        active: this.data.address ? this.data.address.active : '1',
      },
      accountPaymentLiquidation: {
        typeAccountId: this.form.value.accountType,
        bankId: this.form.value.bank,
        numberAccount: this.form.value.accountNumber,
        additionalInformation: this.form.value.additionalInformation,
        titularFirstName1: this.form.value.titularFirstName1,
        titularFirstName2: null,
        titularLastName1: this.form.value.titularLastName1,
        titularLastName2: null,
        documentTypeId: this.form.value.documentTypeId._id,
        titularIdentificationNumber: this.form.value.titularIdentificationNumber,
      },
      legalAcceptance: this.data.legalAcceptance,
      active: this.data.active,
      status: Number(element.id)
    }

    let send = new FormData()
    for (const key in businessPartnerForm) {
      if(businessPartnerForm[key] != null) {
        if(key == 'address' || key == 'accountPaymentLiquidation') {
          send.append(key, JSON.stringify(businessPartnerForm[key]))
        } else {
          send.append(key, businessPartnerForm[key])
        }
      }
    }

    let businessPartnerId = getValidateSomeCompany(this.currentUser.user.businessPartners)._id

    this.businessPartner.update(businessPartnerId, send).subscribe({
      next: (res: BusinessPartnerAttributes) => {

        this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
        //this.loadSave = false
        this.cdr.detectChanges()
      },
      error: (error) => {
        //this.loadSave = false
        this.errorService.getErrorMessage(error)
        this.cdr.detectChanges()
      }
    })
  }
}
