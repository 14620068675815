import ItemQuestionAttributes, { ReportAttributes } from "@albatrosdeveloper/ave-models-npm/lib/schemas/item-question/item-question.entity"
import { Normalizers, Ops, OrderVariant, buildQuery, orderBy, page, where } from "@albatrosdeveloper/ave-utils-npm/lib/utils/query.util"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { environment } from "src/environments/environment"

const URL_API = environment.domain + environment.itemAPI
const URL_S3 = environment.url_s3

@Injectable({
  providedIn: 'root'
})
export class ReportService {

    constructor(public http: HttpClient) { }

    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    })

    update(reportId:string, data: any): Observable<ReportAttributes> {
        const url_api = URL_API + `/report/${reportId}`
        return this.http.patch<ReportAttributes>(url_api, data);
    }

    delete(type:string, reportId:string, questionId:string): Observable<ReportAttributes> {
        const url_api = URL_API + `/report/${type}/${reportId}/${questionId}`
        return this.http.delete<ReportAttributes>(url_api, { headers: this.headers });
    }
}