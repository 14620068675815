import CategoryAttributes from "@albatrosdeveloper/ave-models-npm/lib/schemas/category/category.entity";
import ItemAttributes from "@albatrosdeveloper/ave-models-npm/lib/schemas/item/item.entity";
import DetailPromotionAttributes from "@albatrosdeveloper/ave-models-npm/lib/schemas/promotion/promotion.entity";
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { AuthService } from "src/app/core/service/auth.service";
import { PromotionDetailToSave, PromotionToSave } from "src/app/modules/addons/promotion/promotion.model";
import { ItemWithTotal } from "src/app/modules/item/item.model";
import { PromotionService } from "src/app/services/addons/promotion/promotion.service";
import { QuestionService } from "src/app/services/addons/question/question.service";
import { CategoryService } from "src/app/services/modulus/category/category.service";
import { ItemService } from "src/app/services/modulus/item/item.service";
import { ErrorManagementService } from "src/app/services/system/error-management.service";
import { SnackBarService } from "src/app/services/system/snackbar.service";
import { getValidateSomeCompany } from "src/app/utils/company-validator.strategy";

@Component({
    selector: 'app-form-promotion',
    templateUrl: './form-promotion.component.html',
    styleUrls: ['./form-promotion.component.scss']
  })
  export class FormPromotionComponent implements OnInit {
   
    public form: FormGroup
    public id: any = null
    loadSave = false
    loadData = false

    selectedIndex = 0

    currentUser

    listLevels: any[] = [
      { code: 'BP', name: 'Producto'},
      { code: 'C', name: 'Categoría'},
    ]

    listAmountType: any[] = [
      { code: 'M', name: 'Monto'},
      { code: 'P', name: 'Porcentaje'},
    ]

    // My Products Combo
    loadMyProducts = false
    listMyProducts: ItemAttributes[] = []
    listFilterMyProducts: ItemAttributes[] = []
    searchMyProducts = ''

    // My Categories Combo
    loadMyCategories = false
    listMyCategories: CategoryAttributes[] = []
    listFilterMyCategories: CategoryAttributes[] = []
    searchMyCategories = ''
    categorySelected = null

    productsToAdd = []
    @ViewChild('allProductsSelected', {static: false}) private allProductsSelected: MatOption;

    //Promotion Detail
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    displayedColumns: string[] = [
      'product',
      'type',
      'discount',
      //'maxQuantity',
      //'maxAmount',
      'active',
      'action'
    ];
    

    businessPartnerIdsToSend = []
    productsToSend = []

    dataSource = new MatTableDataSource<any>([])

    constructor(
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<FormPromotionComponent>,
      private errorService: ErrorManagementService,
      private questionService: QuestionService,
      private authenticationService: AuthService,
      private snackBarService: SnackBarService,
      private translate: TranslateService,
      private cdr: ChangeDetectorRef,
      private itemService: ItemService,
      private promotionService: PromotionService,
      private categoryService: CategoryService,
      @Inject(MAT_DIALOG_DATA) public data: any
    ){
      if (this.data?._id) {
        this.id = this.data._id

        //this.displayedColumns.splice(5, 0, 'active');
      }

      this.currentUser = this.authenticationService.currentUserValue

      this.form = this.fb.group({
        name: ['', Validators.compose([Validators.required,])],
        type: ['BP', Validators.compose([Validators.required,])], //Interno
        dateStart: ['', Validators.compose([Validators.required,])],
        hourStart: ['', Validators.compose([Validators.required,])],
        dateEnd: ['', Validators.compose([Validators.required,])],
        hourEnd: ['', Validators.compose([Validators.required,])],
        level: ['', Validators.compose([Validators.required,])],
        //businessPartnerIds: [[], Validators.compose([Validators.required,])],
        categoryIds: [[]],
        maxQuantity: [1, /*Validators.compose([Validators.required,Validators.min(1)])*/],
        maxAmount: [1, /*Validators.compose([Validators.required,Validators.min(1)])*/],
        /*
        status 0 creado (por debajo 0 cuando es superadmin)
        status 1 aprobado por negocio (por debajo 1 cuando creo por backoffice)
        status 2 desaprobado por negocio
        status 3 finalizado
        */
        //status: ['0', Validators.compose([Validators.required,])],
        active: ['0', Validators.compose([Validators.required,])],

      })
    }

    ngOnInit() {
      console.log('data', this.data)
      this.initForm()
      this.onGetMyProducts()
      this.onGetMyCategories()
    }

    initForm() {
      if (this.data == null) {
        this.id = null
      }
      else {
        this.id = this.data._id
        this.form.setValue({
          name: this.data.name ? this.data.name : '',
          type: this.data.type ? this.data.type : '',
          dateStart: this.data.dateStart ? moment(this.data.dateStart, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").format("YYYY-MM-DD"): '',
          hourStart: this.data.dateStart ? moment(this.data.dateStart, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").format('HH:mm:ss') : '',
          dateEnd: this.data.dateEnd ? moment(this.data.dateEnd, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").format("YYYY-MM-DD"): '',
          hourEnd: this.data.dateEnd ? moment(this.data.dateEnd, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").format('HH:mm:ss') : '',
          level: this.data.level ? this.data.level : '',
          categoryIds: this.data.categoryIds ? this.data.categoryIds : [],
          maxQuantity: this.data.maxQuantity ? this.data.maxQuantity : 1,
          maxAmount: this.data.maxAmount ? this.data.maxAmount : 1,
          active: this.data.active ? this.data.active : '1',
        })

        this.data.businessPartners.forEach(bp => {
          this.businessPartnerIdsToSend.push(bp._id)
        })

        let detailAux = []
        this.data.detail.forEach(pd => {
          let promotionDetailAux = {
            _id: pd._id,
            itemId: pd.item._id,
            itemSKU: pd.item.SKU,
            itemName: pd.item.name,
            discount: pd.amountBusinessPartner, //Se cambiara, ahora asume el costo del bp
            amountType: pd.amountType,
            maxQuantity: pd.maxQuantity,
            maxAmount: pd.maxAmount,
            active: pd.active,
            status: pd.status,
            warnItem: false
          }

          detailAux.push(promotionDetailAux)
        })

        this.dataSource.data = detailAux.slice()
      }
    }

    changueSelectLevel(){
      if(this.form.value.level == 'BP'){
        this.onGetMyProducts()
      }else if(this.form.value.level == 'C'){
        this.onGetMyCategories()
      }
    }

    onGetMyProducts(){
      this.loadMyProducts = true
      
      let businessP = getValidateSomeCompany(this.currentUser.user.businessPartners)._id

      this.itemService.getComboByBp(businessP).subscribe({
        next: (res: ItemWithTotal[]) => {
          this.listMyProducts = res[0].data
          this.listFilterMyProducts = this.listMyProducts.slice()
          this.loadMyProducts = false
        },
        error: (error) => { 
          this.loadMyProducts = false
          this.errorService.getErrorMessage(error)
        }
      })
    }

    onGetMyCategories(){
      
      let businessP = getValidateSomeCompany(this.currentUser.user.businessPartners)._id

      this.listMyProducts = []

      this.categoryService.getComboByBusinessPartner(businessP).subscribe({
        next: (res: CategoryAttributes[]) => {
          this.listMyCategories = res
          this.listFilterMyCategories = this.listMyCategories.slice()
          this.loadMyCategories = false
        },
        error: (error) => { 
          this.loadMyCategories = false
          this.errorService.getErrorMessage(error)
        }
      })
    }

    changueOptionsMyCategories(event){
      if (!this.listMyCategories) {
        return;
      }
      let search = event;
      if (!search) {
        this.listFilterMyCategories = this.listMyCategories.slice();
        return;
      } else {
        search = search.toLowerCase();
      }
      this.listFilterMyCategories = this.listMyCategories.filter(item => item.code.toLowerCase().indexOf(search) > -1 || item.name.toLowerCase().indexOf(search) > -1)
    }

    changueSelectCategory(){
      this.onGetProductsByCategory(this.categorySelected)
    }

    onGetProductsByCategory(categoryId){
      let businessP = getValidateSomeCompany(this.currentUser.user.businessPartners)._id

      let filter = {
        businessPartner: businessP,
        categoryId: categoryId
      }

      this.itemService.getProductsByCategory(filter).subscribe({
        next: (res: ItemAttributes[]) => {
          this.listMyProducts = res
          this.listFilterMyProducts = this.listMyProducts.slice()
          this.loadMyProducts = false
        },
        error: (error) => { 
          this.loadMyProducts = false
          this.errorService.getErrorMessage(error)
        }
      })
    }

    changueOptionsMyProducts(event) {
      if (!this.listMyProducts) {
        return;
      }
      let search = event;
      if (!search) {
        this.listFilterMyProducts = this.listMyProducts.slice();
        return;
      } else {
        search = search.toLowerCase();
      }
      this.listFilterMyProducts = this.listMyProducts.filter(item => item.code.toLowerCase().indexOf(search) > -1 || item.name.toLowerCase().indexOf(search) > -1)
    }

    compareObjects(o1: any, o2: any): boolean {
      return o1._id === o2._id
    }

    toggleAllSelectionProducts() {
      console.log(this.allProductsSelected.selected)
      if (this.allProductsSelected.selected) {
        //this.formData.controls.startPoint.patchValue([...this.listFilterCity1.map(item => item.code),0]);
        //this.productsToAdd = [...this.listFilterMyProducts, 0]

        this.productsToAdd = []

        let myProductsSelectedAux = this.listFilterMyProducts.slice()

        myProductsSelectedAux.forEach(mpsa => {
          console.log(mpsa._id)
          if(!this.dataSource.data.some(p => p.itemId == mpsa._id)){
            this.productsToAdd.push(mpsa)
          }
        })
        this.productsToAdd.push(0)


      } else {
        //this.formData.controls.startPoint.patchValue([]);

        this.productsToAdd = []

      }
    }

    tosslePerOneProduct(all){
      if (this.allProductsSelected.selected) {  
        this.allProductsSelected.deselect();
        return false;
      }
      if(this.productsToAdd.length == this.listFilterMyProducts.length){
        
        this.allProductsSelected.select();
      }
    }

    validateDuplicatedProducts(element){
      return this.dataSource.data.some(p => p.itemId == element._id)
    }

    addProductsToList(){
      
      //let productsToAdd: PromotionDetailToSave[] = []

      if(this.allProductsSelected.selected){
        this.productsToAdd.pop()
      }

      let productsAux = []

      console.log(this.productsToAdd)

      this.productsToAdd.forEach(pl => {
        let pdp = {
          itemId: pl._id,
          itemSKU: pl.SKU,
          itemName: pl.name,
          amountType: null,
          discount: null,
          maxQuantity: null,
          maxAmount: null,
          active: '1',
          status: '1',
          isEdit: true,
          warnItem: false
        }
        productsAux.push(pdp)
      })

      console.log('add', productsAux )

      let dataSourceAux = this.dataSource.data.slice()

      this.dataSource.data = dataSourceAux.concat(productsAux)

      console.log('this.dataSource.data', this.dataSource.data)

      this.productsToAdd = []
    }

    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
      this.selectedIndex = tabChangeEvent.index
    }

    onEdit(element) {
      element.isEdit = true
    }

    onSaveEdit(element) {
      if((element.amountType === undefined || element.amountType == null || element.amountType == '') ||
          (element.discount === undefined || element.discount == null || element.discount == '')){
            this.snackBarService.error({ message: 'Complete todos los campos.'})
            return
      }
      element.isEdit = false

      if(this.id != null){

        let send = {
          itemId: element.itemId,
          amountType: element.amountType,
          amountCompanyOrExternal: 0,
          amountBusinessPartner: element.discount,  //Se cambiara, ahora asume el costo del bp
          maxQuantity: element.maxQuantity,
          maxAmount: element.maxAmount,
          active: element.active,
          status: '1',
          promotionId: this.id,
        }

        if(element._id == null){          
          this.promotionService.createOneDetail(send).subscribe({
            next: (res: any) => {
              console.log(res)
              this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
              element._id = res._id
              this.loadSave = false
              this.cdr.detectChanges()
            },
            error: (error) => {
              this.loadSave = false
              this.errorService.getErrorMessage(error)
              this.cdr.detectChanges()
            }
          })
        }else{
          this.promotionService.updateOneDetail(element._id, send).subscribe({
            next: (res: any) => {
              console.log(res)
              this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
              this.loadSave = false
              this.cdr.detectChanges()
            },
            error: (error) => {
              this.loadSave = false
              this.errorService.getErrorMessage(error)
              this.cdr.detectChanges()
            }
          })
        }
      }
    }

    onDelete(index, element): void {
      //this.schedules.splice(index,1)
      //this.dataSource.data = this.schedules.slice()

      let productsAddAux = this.dataSource.data.slice()
      productsAddAux.splice(index,1)
      this.dataSource.data = productsAddAux

    }

    verifiedValue(element){

    }

    onClose(){
      this.dialogRef.close();
    }

    


    onSubmit() {

      if(this.dataSource.data.some(d => d.isEdit == true)){
        this.snackBarService.error({ message: this.translate.instant('Debe guardar todas las promociones.')})
        return;
      }

      this.loadSave = true 

      if(this.id == null){

        let dateStart = moment(this.form.value.dateStart).format('YYYY-MM-DD')
        let dateEnd = moment(this.form.value.dateEnd).format('YYYY-MM-DD')

        let send: PromotionToSave = {
          name: this.form.value.name,
          type: this.form.value.type,
          dateStart: moment.parseZone(moment(dateStart + ' ' + this.form.value.hourStart, "YYYY-MM-DD HH:mm")).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
          dateEnd: moment.parseZone(moment(dateEnd + ' ' + this.form.value.hourEnd, "YYYY-MM-DD HH:mm")).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
          level: this.form.value.level,
          businessPartnerIds: this.id == null ? [getValidateSomeCompany(this.currentUser.user.businessPartners)._id] : this.businessPartnerIdsToSend,
          categoryIds: null,
          maxQuantity: this.form.value.maxQuantity,
          maxAmount: this.form.value.maxAmount,
          status: this.id == null ? '1': this.data.status,
          active: this.id == null ? '1': this.data.status,
          detail: []
        }

        this.dataSource.data.forEach(d => {
          let productAux: PromotionDetailToSave = {
            itemId: d.itemId,
            amountType: d.amountType,
            amountCompanyOrExternal: 0,
            amountBusinessPartner: d.discount,
            maxQuantity: d.maxQuantity,
            maxAmount: d.maxAmount,
            status: this.data?._id != null ? d.status : '1',
          }

          send.detail.push(productAux)
        })

        this.promotionService.create(send).subscribe({
          next: (res: any) => {
            this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
            this.dialogRef.close('success')
            this.loadSave = false
            this.cdr.detectChanges()
  
          },
          error: (error) => {
            this.loadSave = false
            if(Array.isArray(error.error.error)){
              this.snackBarService.error({message: error.error.error.map(el => el.message).join(', ')})
            }else{
              this.errorService.getErrorMessage(error)
            }
            this.cdr.detectChanges()
          }
        })
      }else{
      
        let dateStart = moment(this.form.value.dateStart).format('YYYY-MM-DD')
        let dateEnd = moment(this.form.value.dateEnd).format('YYYY-MM-DD')

        let send: PromotionToSave = {
          name: this.form.value.name,
          type: this.form.value.type,
          dateStart: moment.parseZone(moment(dateStart + ' ' + this.form.value.hourStart, "YYYY-MM-DD HH:mm")).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
          dateEnd: moment.parseZone(moment(dateEnd + ' ' + this.form.value.hourEnd, "YYYY-MM-DD HH:mm")).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
          level: this.form.value.level,
          businessPartnerIds: this.id == null ? [getValidateSomeCompany(this.currentUser.user.businessPartners)._id] : this.businessPartnerIdsToSend,
          categoryIds: null,
          maxQuantity: this.form.value.maxQuantity,
          maxAmount: this.form.value.maxAmount,
          status: this.id == null ? '1': this.data.status,
          active: this.id == null ? '1': this.data.status,
          detail: []
        }

        this.dataSource.data.forEach(d => {
          let productAux: PromotionDetailToSave = {
            itemId: d.itemId,
            amountType: d.amountType,
            amountCompanyOrExternal: 0,
            amountBusinessPartner: d.discount,
            maxQuantity: d.maxQuantity,
            maxAmount: d.maxAmount,
            status: this.data?._id != null ? d.status : '1',
            _id: d._id,
          }

          send.detail.push(productAux)
        })

        this.promotionService.update(this.id, send).subscribe({
          next: (res: any) => {
            this.snackBarService.success({ message: this.translate.instant('TRANSLATE.SUCCESS_ACTION')})
            this.dialogRef.close('success')
            this.loadSave = false
            this.cdr.detectChanges()
  
          },
          error: (error) => {
            this.loadSave = false
            if(Array.isArray(error.error.error)){
              this.snackBarService.error({message: error.error.error.map(el => el.message).join(', ')})
            }else{
              this.errorService.getErrorMessage(error)
            }
            this.cdr.detectChanges()
          }
        })

      }
    }
  }