import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { QuestionComponent } from './question/question.component';
import { PromotionComponent } from './promotion/promotion.component';



const routes: Routes = [
    {
        path: 'question',
        component: QuestionComponent
    },
    {
        path: 'promotion',
        component: PromotionComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AddonsRoutingModule {}
